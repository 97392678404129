export default [{
	"id": 1,
	"name": "EnglishSystem",
	"ckey": "sys",
	"flag": "eng"
}, {
	"id": 2,
	"name": "Turkish",
	"ckey": "turkish"
}, {
	"id": 4,
	"name": "Hebrew",
	"ckey": "heb",
	"flag": "heb",
	"rtl": true
}, {
	"id": 7,
	"name": "English",
	"ckey": "eng",
	"flag": "eng"
}, {
	"id": 8,
	"name": "French",
	"ckey": "french"
}, {
	"id": 9,
	"name": "Romanian",
	"ckey": "romanian"
}, {
	"id": 10,
	"name": "Swedish",
	"ckey": "swedish"
}, {
	"id": 11,
	"name": "Norwegian",
	"ckey": "norwegian"
}, {
	"id": 12,
	"name": "Russian",
	"ckey": "russian"
}, {
	"id": 13,
	"name": "Iceland",
	"ckey": "iceland"
}, {
	"id": 14,
	"name": "Portuguese",
	"ckey": "portuguese"
}, {
	"id": 15,
	"name": "Spanish",
	"ckey": "spanish"
}, {
	"id": 16,
	"name": "Italian",
	"ckey": "italian"
}, {
	"id": 17,
	"name": "Danish",
	"ckey": "danish"
}, {
	"id": 18,
	"name": "Polish",
	"ckey": "polish"
}, {
	"id": 19,
	"name": "EnglishBrinks",
	"ckey": "englishbrinks"
}, {
	"id": 20,
	"name": "HebrewBrinks",
	"ckey": "hebrewbrinks"
}, {
	"id": 21,
	"name": "Dutch",
	"ckey": "dutch"
}, {
	"id": 22,
	"name": "Chinese",
	"ckey": "chinese"
}, {
	"id": 23,
	"name": "Hungarian",
	"ckey": "hungarian"
}, {
	"id": 24,
	"name": "GreekBrinks",
	"ckey": "greekbrinks"
}, {
	"id": 25,
	"name": "EnglishEV",
	"ckey": "englishev"
}, {
	"id": 26,
	"name": "EnglishTransportation",
	"ckey": "englishtransportation"
}, {
	"id": 27,
	"name": "Thai",
	"ckey": "thai"
}, {
	"id": 28,
	"name": "German",
	"ckey": "german"
}, {
	"id": 29,
	"name": "Japanese",
	"ckey": "japanese"
}, {
	"id": 30,
	"name": "Greek",
	"ckey": "greek"
}, {
	"id": 31,
	"name": "testlang",
	"ckey": "test_180320191348199"
}, {
	"id": 32,
	"name": "Euskara",
	"ckey": "euskara"
}, {
	"id": 34,
	"name": "Latvian",
	"ckey": "latvian"
}, {
	"id": 41,
	"name": "Slovenian",
	"ckey": "slovenian"
}, {
	"id": 42,
	"name": "Estonian",
	"ckey": "estonian"
}, {
	"id": 44,
	"name": "SwedishS",
	"ckey": "swedishs"
}, {
	"id": 51,
	"name": "Vietnamese",
	"ckey": "vietnamese"
}, {
	"id": 52,
	"name": "Czech",
	"ckey": "czech"
}, {
	"id": 54,
	"name": "Finnish",
	"ckey": "finnish"
}, {
	"id": 61,
	"name": "NirTest"
}, {
	"id": 62,
	"name": "ArabicBrinks",
	"ckey": "arabicbrinks"
}, {
	"id": 64,
	"name": "Lithuanian",
	"ckey": "lithuanian"
}, {
	"id": 71,
	"name": "AddLangTestC"
}, {
	"id": 81,
	"name": "Bulgarian",
	"ckey": "bulgarian"
}, {
	"id": 91,
	"name": "DavTest"
}
]
