<template>
	<div>
		<form ref="register-form">
			<div class="siteBodyTop">
				<p class="siteRegBodyText" v-html="appData.textData.site_reg_body_text"></p>
			</div>
			<div class="siteBodyMiddle">
				<div class="error">
					<span v-if="$v.$anyError == true">{{ appData.errors.fixFields }}</span
					><br />
				</div>
				<div class="form-group" :class="{ error: $v.firstName.$error }">
					<input type="text" name="firstname" :placeholder="appData.textData.site_reg_first_name" v-model="$v.firstName.$model" />
					<!--<div v-if="submitted && !$v.firstName.required" class="error">First Name is required</div>-->
				</div>
				<div class="form-group" :class="{ error: $v.lastName.$error }">
					<input type="text" name="lastname" :placeholder="appData.textData.site_reg_last_name" v-model="$v.lastName.$model" />
					<!--<div v-if="submitted && !$v.lastName.required">Last Name is required</div>-->
				</div>
				<div class="form-group" :class="{ error: $v.email.$error }">
					<input type="text" name="email" :placeholder="appData.textData.site_reg_email" v-model="$v.email.$model" />
					<!--<div v-if="submitted && $v.email.$error" class="invalid-feedback">
                        <span v-if="!$v.email.required">Email is required</span>
                        <span v-if="!$v.email.email">Email is invalid</span>
                    </div>-->
				</div>

				<div class="form-group" :class="{ error: $v.cardNumber.$error }">
					<input type="text" name="card_number" :placeholder="appData.textData.site_reg_card_number" v-model="$v.cardNumber.$model" />
					<!--<div v-if="submitted && $v.cardNumber.$error">
                        <span v-if="!$v.cardNumber.required">Card is required</span>
                        <span v-if="!$v.cardNumber.email">Card is invalid</span>
                    </div>-->
				</div>
				<p class="followingCards text-alignment" v-html="appData.textData.site_reg_following_cards"></p>

				<div class="cards">
					<img class="siteMainCreditCardImg" :src="appData.textData.site_credit_card_image" />
				</div>
			</div>
			<div class="siteBodyBottom form-group" style="border-top: 1px solid gray" :class="{ error: $v.termsAgree.$error }">
				<label for="termsAgree">
					<input type="checkbox" name="termsAgree" id="termsAgree" class="checkbox" v-model="$v.termsAgree.$model" />
					<span v-html="termsText"></span>
					<div v-if="$v.termsAgree.$error">
						<span>{{ appData.errors.termsAgreement }}</span>
					</div>
				</label>
				<div class="sitePrintBodyButtons">
					<div class="bigButton" :style="{ backgroundColor: appData.colors.registration.secondary }" @click="back">
						{{ appData.textData.btn_registration_back }}
					</div>

					<div id="submitBtn" class="smallButton" :style="{ backgroundColor: appData.colors.registration.primary }" @click="submit">
						{{ appData.textData.btn_registration_submit }}
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import globalMixin from '../mixins/global'
import { required, email, numeric, helpers } from "vuelidate/lib/validators";

const namecheck = helpers.regex("mob", /^[a-zA-Z\- א-ת\u0218-\u021B\u00C0-\u01FF\u4e00-\u9fa5\u0E00-\u0E7F\p{IsCyrillic}]+$/);
const lastnamecheck = helpers.regex("mob", /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\- א-ת\u0218-\u021B\u00C0-\u01FF\u4e00-\u9fa5\u0E00-\u0E7F\p{IsCyrillic}]+$/);

export default {
	name: 'Register',
	mixins: [globalMixin],
	data() {
		return {
			firstName: '',
			lastName: '',
			email: '',
			cardNumber: '',
			termsAgree: false,
		}
	},
	computed: {
		termsText: {
			get: function () {
				return `${this.appData.textData.site_reg_user_read_terms} <a target="_blank" href="https://www.nayax.com/terms-conditions/">${this.appData.textData.site_reg_terms_of_service}</a>, <a target="_blank" href="https://www.nayax.com/privacy_policy/"> ${this.appData.textData.site_reg_privacy_policy}</a>`;
			}
		}
	},
	validations() {
		var self = this;
		return {
			firstName: { required, namecheck, $autoDirty: true },
			lastName: { required, lastnamecheck },
			email: { required, email },
			cardNumber: { required, numeric },
			termsAgree: { required, agree() { return self.termsAgree } }
		}
	},
	methods: {
		submit() {
			var self = this;
			if (self.handleSubmit()) {
				self.$http.post("/ereceiptsubscription/register",
					{
						firstName: self.firstName,
						lastName: self.lastName,
						email: self.email,
						cardNumber: self.cardNumber,
						ereceiptAppId: self.appData.appData.appId,
						appUrl: self.appData.appData.appUrl,
						emailData: self.appData.emailData
					})
					.then(response => {
						if (response.status == 200) {
							self.$awn.success('Subscription Succeeded');
							self.$router.push({
								name: 'chooseReceipt',
								params: {
									subscriptionId: response.data.subscriptionId,
									cardNumber: self.cardNumber,
									sendEmail: true
								}
							});
						}

					});
			}
		},
		handleSubmit(e) {

			// stop here if form is invalid
			this.$v.$touch();
			if (this.$v.$invalid) {
				return false;
			}
			return true;
		}
	}
}
</script>

<style scoped>
.form-group.error {
	animation-name: shakeError;
	animation-fill-mode: forwards;
	animation-duration: 0.6s;
	animation-timing-function: ease-in-out;
}
</style>
