import { mapGetters } from 'vuex'

const requestMixin = {
	computed: {
		...mapGetters(['appData'
		])
	},
	methods:{
		back() {
			this.$router.back();
		},
	}
}
export default requestMixin
