import Vue from 'vue';
import Vuex from 'vuex';
import App from './App.vue';
import 'v2-datepicker/lib/index.css';   // v2 need to improt css
import V2Datepicker from 'v2-datepicker';
import axios from "axios";
import store from './store'
import Vuelidate from 'vuelidate'

import VueAWN from "vue-awesome-notifications"

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import router from './router'
import "bootstrap/dist/css/bootstrap.css";
import config from './config'
import { FontAwesomeIcon } from './fa-icons'
import * as filters from './filters'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import 'vue-awesome-notifications/dist/styles/style.css';

Vue.component('fa-icon', FontAwesomeIcon)
Vue.use(V2Datepicker)
Vue.use(Vuex)
Vue.use(Vuelidate)
Vue.use(VueAWN, {
    durations: { alert: 10000 },
    position: 'top-right'
})

Vue.config.productionTip = false;
Vue.prototype.$http = axios.create({
    baseURL: config.serverURI[process.env.NODE_ENV],
    showLoader: true
});
Vue.prototype.$http.interceptors.request.use(
    config => {
        if (config.showLoader) {
            store.dispatch('loader/pending');
        }
        return config;
    },
    error => {
        if (error.config.showLoader) {
            store.dispatch('loader/done');
        }
        return Promise.reject(error);
    }
);
Vue.prototype.$http.interceptors.response.use(
    response => {
        if (response.config.showLoader) {
            store.dispatch('loader/done');
        }

        return response;
    },
    error => {
        let response = error.response;

        if (response.config.showLoader) {
            store.dispatch('loader/done');
        }
        var appData = store.getters.appData;
        if (!appData)
            return Promise.reject(error);

        return new Promise(function () {
            var errorMsg = null;
            if (response && response.config && !response.config.__isRetryRequest) {
                var dataError = response.data.error;
                if (dataError) {
                    errorMsg = appData.errors[dataError];
                    if (!errorMsg) {
                        errorMsg = appData.errors.general;
                    }
                    if (errorMsg) {
                        Vue.prototype.$awn.alert(errorMsg)
                        return;
                    }
                }
                else if (response.status == 400 && response.data) {
                    //TODO: insert error message
                    var dataErrors = response.data.errors;
                    if (dataErrors && dataErrors.length > 0) {

                    }
                }
            }
            throw error;
        })

    }
);
Vuex.Store.prototype.$http = Vue.prototype.$http;

Vue.component('loading', Loading);
Vue.component('b-dropdown', BDropdown)
Vue.component('b-dropdown-item', BDropdownItem)

for (var name in filters) {
    Vue.filter(name, filters[name])
}

new Vue({
    el: '#app',
    render: h => h(App),
    router,
    store
})
