import Main from '../components/Main.vue'
import Register from '../components/Register.vue'
import Qr from '../components/Qr.vue'
import ChooseReceipt from '../components/ChooseReceipt.vue'
import PrintHistory from '../components/PrintHistory.vue'
import Unsubscribe from '../components/Unsubscribe.vue'
import NotFound from '../components/404.vue'
import Vue from 'vue'
import Router from 'vue-router'
global.$t = Vue.t 

Vue.use(Router, global.$t)

export default new Router({
    hashbang: false,
    mode: 'history',
    routes: [
        {
            path: '/404',
            name: 'notfound',
            component: NotFound,
            caseSensitive: false,
            meta: { independent: true }
        },
        {
            path: '/qr/:tranId/:serial/:site',
            name: 'qr',
            component: Qr,
            caseSensitive: false,
            meta: { independent: true }
        },
        {
            path: '/:siteName/register',
            name: 'register',
            component: Register,
            caseSensitive: false,
        },
        {
            path: '/:siteName/chooseReceipt',
            name: 'chooseReceipt',
            component: ChooseReceipt,
            props: true,
            caseSensitive: false
        },
        {
            path: '/:siteName/printHistory',
            alias: '/:siteName/printFilter',
            name: 'printHistory',
            component: PrintHistory,
            caseSensitive: false
        },
        {
            path: '/:siteName/unsubscribe',
            name: 'unsubscribe',
            component: Unsubscribe,
            caseSensitive: false
        },
        {
            path: '/:siteName',
            name: 'main',
            component: Main,
            caseSensitive: false
        },
    ]
})
