<template>
	<div>
		<form ref="register-form">
			<div class="siteBodyTop">
				<p class="siteRegBodyText" v-html="appData.textData.site_print_body"></p>
			</div>
			<div class="siteBodyMiddle">
				<div class="error">
					<span v-if="$v.$anyError == true">{{ this.appData.errors.fixFields }}</span
					><br />
				</div>
				<div class="form-group" :class="{ error: $v.cardNumber.$error }">
					<input type="text" name="card_number" :placeholder="appData.textData.site_print_card_number" v-model="$v.cardNumber.$model" />
					<!--<div v-if="!$v.firstName.required" class="error">First Name is required</div>-->
				</div>

				<div class="form-group" :class="{ error: $v.date.$error }">
					<v2-datepicker-range v-model="date" :unlink-panels="true" lang="en" :placeholder="appData.textData.site_print_date_range"></v2-datepicker-range>
					<!--<div v-if="!$v.firstName.required" class="error">First Name is required</div>-->
				</div>
				<div class="form-group" :class="{ error: $v.firstName.$error }">
					<input type="text" name="firstname" :placeholder="appData.textData.site_reg_first_name" v-model="$v.firstName.$model" />
					<!--<div v-if="!$v.firstName.required" class="error">First Name is required</div>-->
				</div>
				<div class="form-group" :class="{ error: $v.lastName.$error }">
					<input type="text" name="lastname" :placeholder="appData.textData.site_reg_last_name" v-model="$v.lastName.$model" />
					<!--<div v-if="!$v.lastName.required">Last Name is required</div>-->
				</div>
				<div class="form-group" :class="{ error: $v.email.$error }">
					<input type="text" name="email" :placeholder="appData.textData.site_print_email" v-model="$v.email.$model" />
					<!--<div v-if="$v.email.$error" class="invalid-feedback">
                        <span v-if="!$v.email.required">Email is required</span>
                        <span v-if="!$v.email.email">Email is invalid</span>
                    </div>-->
				</div>

					<label for="sendEmail" id="sendViaEmail">
						<input type="checkbox" name="sendEmail" id="sendEmail" class="checkbox" v-model="sendEmail" />
						<span v-html="appData.textData.site_print_do_email"></span>
						<span v-html="appData.textData.site_future_emails"></span>
					</label>

				<p class="followingCards" style="text-align: left" v-html="appData.textData.site_main_credit_card_headercreditCardsHeader"></p>

				<div class="cards">
					<img class="siteMainCreditCardImg" :src="appData.textData.site_credit_card_image" />
				</div>
			</div>
			<div class="siteBodyBottom" style="border-top: 1px solid gray">
				<div class="sitePrintBodyButtons">
					<div class="bigButton" :style="{ backgroundColor: appData.colors.purchaseHistory.secondary }" @click="back">
						{{ appData.textData.btn_purchaseHistory_back }}
					</div>

					<div id="submitBtn" class="smallButton" :style="{ backgroundColor: appData.colors.purchaseHistory.primary }" @click="submit">
						{{ appData.textData.btn_purchaseHistory_submit }}
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import globalMixin from '../mixins/global'
import { required, email, numeric, helpers } from "vuelidate/lib/validators";
const namecheck = helpers.regex("mob", /^[a-zA-Z\- א-ת\u0218-\u021B\u00C0-\u01FF\u4e00-\u9fa5\u0E00-\u0E7F\p{IsCyrillic}]+$/);
const lastnamecheck = helpers.regex("mob", /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\- א-ת\u0218-\u021B\u00C0-\u01FF\u4e00-\u9fa5\u0E00-\u0E7F\p{IsCyrillic}]+$/);
export default {
	name: 'PrintHistory',
	mixins: [globalMixin],
	data() {
		return {
			date: null,
			firstName: null,
			lastName: null,
			email: null,
			cardNumber: null,
			sendEmail: false,
			submitted: false,
		}
	},
	validations() {
		return {
			firstName: { required, namecheck, $autoDirty: true },
			lastName: { required, lastnamecheck },
			email: { required, email },
			cardNumber: { required, numeric },
			date: { required }
		}
	},
	methods: {
		submit() {
			if (this.handleSubmit()) {
				var self = this;

				var fromDate = self.date[0].toLocaleDateString('ko-KR');
				var toDate = self.date[1].toLocaleDateString('ko-KR');
				this.$http.post("/ereceiptsubscription/search",
					{
						firstName: this.firstName,
						lastName: this.lastName,
						email: this.email,
						cardNumber: this.cardNumber,
						ereceiptAppId: this.appData.appData.appId,
						fromDate: fromDate,
						toDate: toDate
					})
					.then(response => {
						if (response.status == 200) {
							self.$router.push({
								name: 'chooseReceipt',
								params: {
									subscriptionId: response.data.subscriptionId,
									cardNumber: self.cardNumber,
									fromDate: fromDate,
									toDate: toDate,
									sendEmail: this.sendEmail
								}
							});
						}

					});
			}
		},
		handleSubmit(e) {
			this.submitted = true;

			// stop here if form is invalid
			this.$v.$touch();
			if (this.$v.$invalid) {
				return false;
			}
			return true;
		}
	}
}
</script>

<style scoped>
#sendViaEmail {
	margin-top: 2px;
	margin-left: 3px;
	font-size: 0.73rem;
	display: inline-block;
}

.v2-date-wrap {
	border: 0;
	border-bottom: 1px solid #e1e2e6;
}

.v2-date-range-wrap {
	min-width: 100%;
}

.v2-date-wrap:hover {
	border-color: #e1e2e6;
}
</style>
<style>
.v2-date-wrap .v2-picker-trigger {
	color: #6884a9 !important;
	padding-left: 0 !important;
}

.v2-date-wrap .v2-picker-trigger.empty-text {
	color: #757575 !important;
}

  .v2-date-clear{
	display:none !important;
  }

  input[type="checkbox"] {
    width: 12px;
    height: 21px;
    margin-right: 5px;
  }
  .v2-date-range-wrap{
	margin-left:8px;
  }
</style>
