var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app",style:({opacity: _vm.isEmailSending ? '0.8' : '1' })},[_c('SendingOverlay',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEmailSending),expression:"isEmailSending"}]}),_vm._m(0),_c('div',{staticClass:"row transTitle"},[_c('span',{staticClass:"title"},[_vm._v("E-Receipt")]),_c('div',{staticClass:"separator_line"}),_c('div',{staticClass:"transInfo"},[_c('span',{staticClass:"transName"},[_vm._v("Receipt #")]),_c('span',{staticClass:"transNum"},[_vm._v(_vm._s(_vm.tranId))])])]),_c('div',{staticClass:"ereceipt_handlers_wrap col"},[_c('Row',[_c('div',{staticClass:"button_group"},[_c('a',{staticClass:"sendButton",staticStyle:{"text-align":"center"},style:({
                                direction: _vm.isRtl ? 'rtl':'ltr',
                                display: _vm.isEmailFlow ? 'none' : 'block',
                                display: _vm.isInvalidTransaction ? 'none' : 'block'
                               }),attrs:{"target":"_blank","href":_vm.receiptDownloadUrl,"download":_vm.downloadName}},[_c('div',{staticClass:"save_img"},[_c('img',{attrs:{"src":require("../assets/images/save.svg")}}),_c('span',[_vm._v(_vm._s(('Save')))])])]),_c('div',{staticClass:"btnSpace",style:({display: _vm.isEmailFlow? 'none' : 'block'})}),_c('div',{staticClass:"send_button_container",class:_vm.isEmailFlow ? 'send_button_long' : 'send_button_short',style:({                        
                                direction: _vm.isRtl ? 'rtl':'ltr',
                                margin: _vm.isEmailFlow ? '20px 0px 0px 0px' : '20px 0px 0px 35px',
                                display: _vm.isInvalidTransaction ? 'none' : 'block'
                               })},[_c('button',{staticClass:"sendButton",class:_vm.isEmailFlow ? 'sendReceiptEmail' : 'sendReceipt',attrs:{"id":"sendReceiptBtn","disabled":_vm.isEmailSending},on:{"click":_vm.sendEreceiptHandle}},[_c('img',{staticStyle:{"margin-right":"6px"},style:({ display: _vm.isEmailFlow ? 'none' : 'inline-block' }),attrs:{"src":require("../assets/images/send.svg")}}),_c('span',[_vm._v(_vm._s(('Send')))])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],ref:"ereceipt_email_input",staticClass:"ereceipt_email_input",class:{
                                       email_flow_input: _vm.isEmailFlow,
                                   },style:({
                                       borderColor: _vm.isValidErr ? '#AA0000' : '#262626',
                                   }),domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isValidErr && _vm.isEmailFlow),expression:"isValidErr && isEmailFlow"}],staticClass:"err_validation_con"},[_c('span',{staticClass:"err"},[_vm._v(_vm._s(_vm.errors))])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isInvalidTransaction),expression:"isInvalidTransaction"}],staticClass:"err_validation_transaction"},[_c('span',{staticClass:"err"},[_vm._v(_vm._s(_vm.errors))])])])],1),_c('AppFooter')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row ereceiptImg"},[_c('img',{attrs:{"src":require("../assets/images/exit_icon.svg")}})])}]

export { render, staticRenderFns }