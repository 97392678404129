<template>
	<div>
		<div class="siteBodyTop">
			<p style="white-space: pre-line" class="siteMainBodyText" v-html="appData.textData.site_main_body_text"></p>
		</div>
		<div class="siteBodyMiddle">
			<div class="siteBodyMiddleText">
				<p class="siteMainButtonsHeader" v-html="appData.textData.site_main_buttons_header"></p>
			</div>
			<div class="siteMainBodyButtons">
				<router-link
					:to="{ name: 'register', params: { siteName: this.$store.state.appNameFromUrl } }"
					class="bigButton"
					:style="{ backgroundColor: appData.colors.mainPage.primary }"
					>{{ appData.textData.btn_main_register }}</router-link
				>
				<router-link
					:to="{ name: 'printHistory', params: { siteName: this.$store.state.appNameFromUrl } }"
					class="bigButton"
					:style="{ backgroundColor: appData.colors.mainPage.secondary }"
					>{{ appData.textData.btn_main_print }}</router-link
				>
			</div>
		</div>
		<div class="siteBodyBottom">
			<div class="line"></div>
			<p style="white-space: pre-line" class="siteMainMiddleText" v-html="appData.textData.site_main_middle_text"></p>

			<p class="siteMainCreditCardHeader" v-html="appData.textData.site_main_credit_card_header"></p>

			<div class="cards">
				<img class="siteMainCreditCardImg" :src="appData.textData.site_credit_card_image" />
			</div>

			<p style="white-space: pre-line" class="siteMainBottomText" v-html="appData.textData.site_main_bottom_text"></p>
		</div>
	</div>
</template>

<script>
import globalMixin from '../mixins/global'

export default {
	name: 'Main',
	mixins: [globalMixin],
	data() {
		return {};
	}
}
</script>

<style scoped>
.rtl-language .siteMainCreditCardHeader {
	float: right;
}

.siteMainCreditCardHeader {
	max-width: 450px;
	float: left;
}
</style>

