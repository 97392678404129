<template>
	<div :class="{ 'rtl-language': currentLanguage && currentLanguage.rtl }">
		<loading :active.sync="this.$store.state.loader.loading"></loading>
		<router-view v-if="$route.meta.independent === true"></router-view>
		<div v-if="this.appData != null" class="siteMain">
			<div>
				<b-dropdown
					:class="currentLanguage && currentLanguage.rtl ? 'float-left' : 'float-right'"
					id="lang-button"
					v-if="languageOptions && currentLanguage && currentLanguage.name && languageOptions.length > 1"
				>
					<template slot="button-content">
						<img :src="getLanguageFlag(currentLanguage)" class="flag-icon" /> {{ currentLanguage.name }}
					</template>
					<b-dropdown-item v-for="lang in languageOptions" :key="lang.id" href="#" @click="selectLanguage(lang)">
						<img :src="getLanguageFlag(lang)" class="flag-icon" />{{ lang.name }}
					</b-dropdown-item>
				</b-dropdown>
			</div>

			<div class="siteHeader">
				<div class="siteLeftLogo">
					<img :src="appData.textData.site_top_left_logo" />
				</div>
				<div class="siteRightLogo">
					<img :src="appData.textData.site_top_right_logo" />
				</div>
			</div>
			<div class="panel panelBorderColor">
				<div id="app" v-bind:class="{ fullScreen: !showImage }">
					<div class="siteContent">
						<div class="siteHeadline">
							<h1 class="sitePrimaryColor siteHeadlineText">{{ headline }}</h1>
						</div>
						<div class="siteBody">
							<router-view></router-view>
						</div>
					</div>
				</div>
				<div id="machine_pic_panel" v-show="showImage" :style="{ backgroundImage: 'url(' + this.appData.textData.site_machine_image + ')' }">
				</div>
			</div>
			<div class="siteFooter">
				<div class="siteMainFooterText" v-html="appData.footerText"></div>
			</div>
		</div>
	</div>
</template>

<script>
import languages from './config/languages.js'
import config from './config'
import { mapGetters } from 'vuex'

export default {
	name: 'app',
	data() {
		return {
			showImage: true,
			headline: "eReceipt"
		}
	},
	methods: {
		setLayout: function () {
			var self = this;
			if (self.appData) {
				var headline = self.appData.headlines[self.$route.name];
				if (headline)
					self.headline = headline;
			}

			self.showImage = self.$route.name != 'chooseReceipt';
			self.$store.commit('setPageHeader', self.headline);
			document.querySelector('body').style.background =
				"url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30'><circle fill='" + self.appData?.textData?.site_primary_color?.replace("#", "%23") + "' cx='15' cy='15' r='10' fill-opacity='0.1' /></svg>\")"

		},
		selectLanguage(language) {
			this.$store.commit('setCurrentLanguage', language.id)
		},
		getLanguageFlag(lang) {
			var langFlagName = lang.flag;
			if (!langFlagName)
				langFlagName = lang.ckey;
			return `/assets/images/languages/${langFlagName}.svg`;
		}
	},
	async created() {
		var self = this;
		var siteName = this.$route.params.siteName;
		if (siteName) {
			self.$store.state.appNameFromUrl = siteName;
			var appData = self.appData;
			//get data from server only if there is no data in local storage, or if the data datetime is more than 10 mins ago
			var allowedLimit = new Date().getTime() - (1000 * 60 * 10);//10 mins
			if (!appData || appData.version != config.version || allowedLimit > appData.created || isNaN(appData.created)) {
				await self.$store.dispatch("setAppData");
			}
		}
	},
	computed: {
		languageOptions() {
			var self = this;
			return languages
				.filter(l => self.availableLanguages.includes(l.id))
				.sort((a, b) => a.name > b.name ? 1 : -1);
		},
		currentLanguage() {
			var self = this;
			return languages.find(a => a.id == self.$store.state.currentLanguage);
		},
		...mapGetters([
			'appData',
			'availableLanguages'
		])
	},
	watch: {
		$route() {
			this.setLayout();
		},
		appData() {
			this.setLayout();
		}
	},

}

</script>

<style>
p {
	margin: 0;
}

/* -------------------- RTL SUPPORT ----------------*/
.rtl-language input::placeholder {
	text-align: right;
}

.rtl-language {
	direction: rtl;
	text-align: right;
}

.rtl-language .siteLeftLogo {
	float: right;
}
.rtl-language .siteRightLogo {
	float: left;
}
.rtl-language #app {
	float: right;
	padding-left: 0;
	padding-right: 5%;
}

.rtl-language #machine_pic_panel {
	float: right;
	left: 0;
	right: auto;
}

.rtl-language .bigButton,
.rtl-language .smallButton {
	float: right;
}

.rtl-language .bigButton:nth-of-type(2),
.rtl-language .smallButton:nth-of-type(2) {
	margin-left: auto;
	margin-right: 4%;
}

.rtl-language input {
	margin-right: inherit;
	margin-left: 5px;
	float: left;
}

.rtl-language .sitePrintBodyButtons {
	margin-left: 50px;
	margin-right: auto;
	float: right;
}

.rtl-language .checkbox {
	float: right;
}

.rtl-language h1.siteHeadlineText {
	text-align: right;
}
.rtl-language .text-alignment {
	text-align: right;
}

.rtl-language .flag-icon {
	margin-left: 10px;
	margin-right: auto;
}
.rtl-language #lang-button .dropdown-item {
	text-align: start;
}

/* -------------------- END RTL SUPPORT ----------------*/
.text-alignment {
	text-align: left;
}
h1.siteHeadlineText {
	font-family: "Roboto", sans-serif;
	text-transform: capitalize;
	font-size: 1.35rem;
	vertical-align: middle;
	text-align: left;
	margin: 0;
	font-weight: bold;
	padding-bottom: 18px;
	line-height: 1.5rem;
}
.checkbox {
	float: left;
	width: auto;
}

.flag-icon {
	width: 25px;
	margin-right: 10px;
}
#lang-button .dropdown-menu.show {
	min-width: 0px;
	left: 7px !important;
}
#lang-button .dropdown-item {
	padding: 0.15rem 1rem;
}

.panel {
	border: 0px solid black;
	position: relative;
	background-size: auto 100%;
	box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.3);
	-o-box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.3);
	background-repeat: no-repeat;
	background-color: white;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	border-radius: 0px !important;
	min-height: 630px;
	background-image: none;
	display: inline-block;
	width: 100%;
}

.siteMain {
	height: auto;
	margin: auto;
	width: 810px;
}

.siteMain:after {
	content: "";
	display: block;
	clear: both;
}

.siteHeader {
	height: 90px;
	margin-bottom: 12px;
	margin-top: 12px;
}

.fullScreen .siteContent {
	width: 100%;
}

.siteContent {
	height: 100%;
	width: 95%;
}

.siteContent:after {
	content: "";
	display: block;
	clear: both;
}

.siteFooter {
	height: auto;
	text-align: center;
	margin-top: 18px;
	font-size: 0.75rem;
	display: inline-block;
	width: 100%;
}

.siteLeftLogo,
.siteRightLogo {
	width: 50%;
	height: 90px;
	background-repeat: no-repeat;
}

.siteLeftLogo {
	float: left;
	display: inline;
	padding-left: 36px;
}

.siteLeftLogo img {
	height: 100%;
}

.siteRightLogo {
	float: right;
	display: inline;
	background-position: center right 36px;
	background-size: 70% auto;
	width: 40%;
}

.siteRightLogo img {
	height: 100%;
}

#app {
	float: left;
	padding: 5%;
	padding-right: 0;
	width: 50%;
}

#app.fullScreen {
	padding: 5%;
	width: 100%;
}

.bigButton,
.smallButton {
	padding: 0 21px;
	width: 48%;
	height: 75px;
	display: table;
	float: left;
	font-size: 0.9rem;
	text-align: center;
	cursor: pointer;
	border: 0 solid gray;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 60px 60px 60px 60px !important;
	-webkit-border-radius: 60px 60px 60px 60px !important;
	-moz-border-radius: 60px 60px 60px 60px !important;
	-o-border-radius: 60px 60px 60px 60px !important;
	margin-bottom: 0px;
	opacity: 1;
	text-decoration: none;
	padding: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
}

.bigButton:nth-of-type(2),
.smallButton:nth-of-type(2) {
	margin-left: 4%;
}

.bigButton:hover,
.smallButton:hover {
	opacity: 0.75;
}

form {
	max-width: 438px;
	width: 100%;
	margin-top: 15px;
}

input {
	margin-right: 5px;
	width: 98%;
	border: 0px solid #e2e2e2;
	border-bottom: 1px solid #e2e2e2;
	padding: 0.6rem 0;
	color: #6884a9 !important;
	outline: none;
	float: right;
	font-size: 0.9rem;
}

p.followingCards {
	padding: 9px 0 0;
	font-size: 0.75rem;
	display: inline-block;
	width: 100%;
}

.sitePrintBodyButtons {
	margin-right: 50px;
	float: left;
}

.siteMainBodyButtons,
.sitePrintBodyButtons {
	display: block;
	margin-bottom: 0px;
	margin-top: 21px;
	width: 100%;
}

.form-group.error input {
	border-color: red !important;
}

.form-group.error .v2-date-wrap {
	border-color: red !important;
}

div.error {
	color: red;
}
#machine_pic_panel {
	width: 45%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
}
.vld-overlay .vld-icon{
        display:none;
    }

/* responsive: screen size between tablet 950px to iphone 768px */
@media screen and (min-width: 768px) and (max-width: 950px) {
	.siteMain {
		width: 720px;
	}

	.panel {
		background-position: center right -90%;
	}

	.siteMainBodyButtons > div,
	.sitePrintBodyButtons > div {
		height: 90px;
	}
}

/* responsive: screen size between tablet 768px to iphone 640px */
@media screen and (min-width: 641px) and (max-width: 767px) {
	.siteMain {
		width: 640px;
	}

	.panel {
		background-position: center right -270%;
	}

	.bigButton,
	.smallButton {
		height: 90px;
		padding: 0 21px;
		width: 49%;
	}

	.bigButton:nth-of-type(2),
	.smallButton:nth-of-type(2) {
		margin-left: 2%;
	}
}
/* responsive: screen size between tablet 640px to iphone 480px */
@media screen and (min-width: 320px) and (max-width: 640px) {
	body {
		background-color: #FFFFFF;
	}

	.siteHeader {
		background-color: #fff;
	}

	.siteLeftLogo {
		text-align: center;
		width: 100%;
		padding: 0;
	}

	.siteLeftLogo img {
		height: 100%;
	}

	.siteMain {
		width: 100%;
	}

	.panel {
		box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
		-webkit-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
		-moz-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
		-o-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
		border-color: transparent !important;
		border-radius: 0px !important;
		background-image: none;
		padding-top: 0px;
	}

	.siteContent {
		border-top: 1px solid #e2e2e2;
		padding-top: 15px;
		width: 100%;
	}

	.siteHeadline {
		width: 100%;
	}

	.siteBodyTop {
		width: 100%;
	}

	.siteBodyMiddle {
		width: 100%;
	}

	.siteBodyBottom {
		width: 100%;
	}

	.err {
		width: 100% !important;
	}

	form {
		max-width: 100%;
	}

	form label.doEmail {
		width: 100%;
	}

	label.doEmail span {
		width: 90%;
		display: inline-block;
	}

	.bigButton,
	.smallButton {
		padding: 0 21px;
		width: 49%;
	}

	.bigButton:nth-of-type(2),
	.smallButton:nth-of-type(2) {
		margin-left: 2%;
	}

	#machine_pic_panel {
		display: none !important;
	}

	#app {
		width: 90%;
	}
	.siteFooter {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.v2-picker-range-panel-wrap {
		top: 0px !important;
		left: 0px !important;
		width: 100% !important;
	}
	.v2-picker-range-panel {
		display: unset !important;
		margin-left: 0px !important;
		width: 100% !important;
	}
	.v2-picker-panel__table {
		width: 100% !important;
	}
}
/* responsive: screen size between tablet 480px to iphone 320px */
@media screen and (min-width: 320px) and (max-width: 479px) {
	.siteHeader {
		background-color: #fff;
	}

	.siteLeftLogo {
		background-position: center center;
		width: 100%;
	}

	.siteRightLogo {
		display: none;
	}

	.siteMain {
		width: 100%;
	}

	.bigButton,
	.smallButton {
		padding: 0 36px;
		width: 100%;
		margin-bottom: 15px;
	}

	.bigButton:nth-of-type(2),
	.smallButton:nth-of-type(2) {
		margin-left: 0%;
		margin-bottom: 0px;
	}

	.siteMainBodyButtons,
	.sitePrintBodyButtons {
		margin-top: 15px;
		width: 100% !important;
		margin-right: 0 !important;
	}
	#app {
		width: 90%;
	}
}

@media print {
	body {
		background: none !important;
	}

	header,
	footer {
		display: none;
	}

	.siteHeadline {
		width: 100%;
	}

	h1.siteHeadlineText {
		font-size: 1.3rem;
		text-align: left;
	}

	.siteBodyBottom {
		display: none;
	}

	.siteLeftLogo {
		padding: 0;
		text-align: center;
		width: 100%;
	}

	.siteRightLogo {
		display: none;
	}

	.panel {
		box-shadow: 0px 0px 0px rgba(255, 255, 255, 0);
		-webkit-box-shadow: 0px 0px 0px rgba(255, 255, 255, 0);
		-moz-box-shadow: 0px 0px 0px rgba(255, 255, 255, 0);
		-o-box-shadow: 0px 0px 0px rgba(255, 255, 255, 0);
		min-height: 0 !important;
		height: auto !important;
	}
	.bigButton,
	.smallButton {
		display: none;
	}
}
</style>

