import Vuex from 'vuex'
import Vue from 'vue'
import router from '../router'
import createPersistedState from "vuex-persistedstate";
import { loader } from './modules/loader';
import config from '../config'

//load Vuex
Vue.use(Vuex);

var state = {
    appNameFromUrl: null,
    cachedApps: [],
    currentLanguage: 7,
    pageHeader: ''
};

const getters = {
    appData: (state) => {
        if (state.cachedApps) {
            var currentsite = state.appNameFromUrl;
            var apps = state.cachedApps.filter(a => a.name == currentsite);
            if (apps.length > 0) {
                var currentLanguage = state.currentLanguage;
                var app = apps.find(a => a.languageId == currentLanguage);
                if (app)
                    return app;
                return apps[0];
            }
        }
        return null;
    },
    availableLanguages: (state) => {
        if (!state.cachedApps || !state.appNameFromUrl)
            return [];

        var currentsite = state.appNameFromUrl;
        return state.cachedApps
            .filter(a => a.name == currentsite)
            .map(a => a.languageId);
    }
};

const actions = {
    async setAppData({ commit }) {
        var self = this;
        var data = null;
        try {
            var response = await self.$http.get("/ereceiptsite/app-data/" + this.state.appNameFromUrl);
            data = response.data;
        } catch (error) {
            if (error && error.response && error.response.status == 404) {
                router.push("404");
                //redirect to 404
            }
            else {
                //redirect to 500 error page
            }
        }
        if (data)
            commit('addAppData', response.data)
    },

}
function transformText(text) {
    if (!text)
        return text;
    return text.replace(/(?:\\r\\n|\\r|\\n)/g, '<br />');
};

function getButtons(app) {
    var buttons = app.designData?.btnText ?? {};

    var props = ['settings', 'mainPage', 'registration', 'verificationEmail', 'afterRegistration', 'purchaseHistory', 'result', 'confirmation']

    var mapProps = {
        "registration": "buttonRegister",
        "": "buttonPrint",

    }
    //buttonPrint buttonRegister
    props.forEach(element => {
        if (!buttons[element])
            buttons[element] = {};

        if (!buttons[element].primary)
            buttons[element].primary = app.appData[mapProps[element]];

        if (!buttons[element].secondary)
            buttons[element].secondary = app.appData.buttonBack;

    });

}

function getDesignColors(designData) {

    var defaults = {
        primary: 'red',
        secondary: 'gray'
    };
    var pages = ['mainPage', 'registration', 'verificationEmail', 'afterRegistration', 'purchaseHistory', 'result', 'confirmation'];

    if (!designData.colors)
        designData.colors = {};

    var colors = designData.colors;
    if (!designData.colors.settings)
        colors.settings = {};

    for (const [key, value] of Object.entries(defaults)) {
        if (!colors.settings[key])
            colors.settings[key] = value;

        pages.forEach(element => {
            if (!colors[element])
                colors[element] = {};

            if (designData.is_apply_design_to_all || !colors[element][key]) {
                colors[element][key] = colors.settings[key];
            }
        });
    }
    return colors;
}

const mutations = {
    addAppData(state, apps) {
        var defaultLang = 7;
        var appNameFromUrl = this.state.appNameFromUrl;
        for (let index = 0; index < apps.length; index++) {
            const appData = apps[index];

            if (appData.isDefaultLanguage && appData.languageId)
                defaultLang = appData.languageId;

            var textData = appData.siteTranslationData;
            var headlines = {
                'chooseReceipt': textData.site_res_headline,
                'register': textData.site_reg_headline,
                'printHistory': textData.site_print_headline,
                'unsubscribe': textData.site_receipt_apply,
                'main': textData.site_main_headline_text

            };
            var app = {
                created: new Date().getTime(),
                name: appNameFromUrl,
                languageId: appData.languageId,
                appData: appData,
                textData: appData.siteTranslationData,
                colors: getDesignColors(appData.designData),
                headlines: headlines,
                version: config.version,
                emailData: {
                    emailPrefix: appData.emailPrefix,
                    emailUnsubscribeText: appData.emailUnsubscribeText,
                    emailUnsubscribeLinkText: appData.emailUnsubscribeLinkText,
                    emailBody: appData.emailBody,
                    emailSubject: appData.emailSubject,
                    emailFrom: appData.emailFrom,
                    emailRegisterSubject: appData.emailRegisterSubject,
                    emailRegisterBody: appData.emailRegisterBody
                },
                errors: {
                    site_err_card_exists: textData.site_err_card_exists,
                    site_err_no_trans: textData.site_err_no_trans,
                    site_err_no_card_number: textData.site_err_no_card_number,
                    emailInvalid: textData.site_err_email_invalid,
                    firstNameInvalid: textData.site_err_first_name_invalid,
                    fixFields: textData.site_err_fix_fields,
                    general: textData.site_err_general,
                    lastNameInvalid: textData.site_err_last_name_invalid,
                    noFromDate: textData.site_err_no_from_date,
                    noPurchase: textData.site_err_no_purchase,
                    noUntilDate: textData.site_err_no_until_date,
                    termsAgreement: textData.site_err_terms_agreement
                },
                language: {
                    languageId: appData.languageId,
                    languageName: appData.languageName,
                    languageKey: appData.languageKey,
                    isRTL: appData.isRTL
                }
            };

            
            // var buttons = designData?.btnText ?? {};
            // if (buttons.settings == null)
            //     buttons.settings = {}


            app.footerText = transformText(app.textData.site_footer)
            if (!app.footerText)
                app.footerText = "Welcome to <br/> Arrow GTP";

            var prevApp = state.cachedApps.find(a => a.name == app.name && a.languageId == app.languageId)
            if (prevApp) {
                var prevIndex = state.cachedApps.indexOf(prevApp);
                state.cachedApps.splice(prevIndex, 1);
            }
            state.cachedApps.push(app);
        }
        state.currentLanguage = defaultLang;
    },
    setCurrentLanguage(state, id) {
        state.currentLanguage = id;
    },
    setPageHeader(state, header) {
        state.pageHeader = header;
    }

}

export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations,
    // plugins: [
    //     createPersistedState({
    //         paths: ['cachedApps'],
    //     }),
    // ],
    modules: {
        loader
    }
})
