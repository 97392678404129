<template>
    <footer ref="footer">
        <div>
            <div class="nayax_link_list_con">
                <ul>
                    <li>
                        <a href="https://www.nayax.com/about-nayax/">
                            {{ ('About Us') }}
                        </a>
                    </li>
                    <li>
                        <a href="https://www.nayax.com/terms-conditions/">
                            {{ ('terms & conditions') }}
                        </a>
                    </li>
                    <li>
                        <a href="https://www.nayax.com/privacy_policy/">
                            {{ ('privacy policy') }}
                        </a>
                    </li>
                    <li>
                        <a href="https://www.nayax.com/support/">{{ ('support') }}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="nayax_social_wrap">
            <div class="nayax_logo_con">
                <img src="../assets/images/nayax_logo.svg" />
            </div>
            <div class="social_con">
                <ul>
                    <li>
                        <a href="https://www.instagram.com/nayaxvend/"
                            ><img src="../assets/images/social/instagram.svg"
                        /></a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/company/nayaxvend/mycompany/"
                            ><img src="../assets/images/social/linkdin.svg"
                        /></a>
                    </li>
                    <li>
                        <a href="https://twitter.com/NayaxGlobal"
                            ><img src="../assets/images/social/twitter.svg"
                        /></a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/nayaxvending?fref=ts"
                            ><img src="../assets/images/social/facebook.svg"
                        /></a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="c_tag_wrap">
            <h5 v-html="('&copy; all rights reserved')"></h5>
        </div>
    </footer>
</template>

<script>
    export default {
        name: 'AppFooter'
    };
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
footer,
footer.col {
    background-color: #262626;
}
footer {
    min-height: 50px;
}
footer {
    margin-top: auto;
}
.col,
.inner_wrap {
    background-color: #fff;
}

.nayax_link_list_con a {
    font-size: 20px;
    text-transform: capitalize;
    text-rendering: geometricprecision;
    display: inline-block;
    margin-bottom: 2.2rem;
    font-weight: normal;
    color: #ffffff;
    font-family: 'Inter';
}

.nayax_link_list_con ul {
    padding-left: 20px;
    border-left: 1px solid #ffc900;
}

.nayax_link_list_con ul li {
    list-style-type: none;
}

.nayax_link_list_con {
    margin-top: 50px;
    padding-left: 25px;
}

.nayax_social_wrap {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    direction: ltr;
}

.nayax_logo_con {
    margin-top: 10px;
    display: inline-block;
    padding-left: 25px;
}

.nayax_logo_con img {
    width: 145px;
}

.social_con {
    display: inline-block;
    margin-right:40px;
}

.social_con li {
    padding: 0 40px;
    display: inline-block;
}
.social_con li a img{
    width:30px;
    height:30px;
}

.social_con img {
    height: 15px;
}

.c_tag_wrap {
    padding: 15px 0 10px 25px;
}

.c_tag_wrap h5 {
    color: white;
    font-weight: normal;
    color: #B3B3B3;
    font-size:0.9rem;
    font-family: 'Inter';
}

.white_txt {
    color: #ffffff;
}

.white_txt input {
    color: #ffffff;
}

.gray_txt {
    color: #979797;
}

.black_txt {
    color: #262626;
}

.oi-star {
    font-size: 0.8rem;
}

.line_break {
    display: block;
}

.center {
    text-align: center;
}

.bold {
    font-weight: bolder;
}

.align-items_bottom {
    align-items: flex-end;
}

.align-items_center {
    align-items: center;
}

h3 {
    font-size: 1.2rem;
    display: inline-block;
    margin-bottom: 0;
}

h4,
p {
    font-size: 0.8rem;
    text-transform: capitalize;
    text-rendering: geometricprecision;
    display: inline-block;
    margin-bottom: 0rem;
    line-height: 0.9rem;
    font-weight: 500;
}

h5,
a {
    font-size: 0.65rem;
    text-transform: capitalize;
    text-rendering: geometricprecision;
    display: inline-block;
    margin-bottom: 0rem;
    line-height: 0.65rem;
}

@media screen and (min-width: 320px) and (max-width: 640px) {
    .nayax_link_list_con {
    margin-top: 20px;
    padding-left: 15px;
}
.nayax_link_list_con ul {
    padding-left: 10px;
    }
    .nayax_link_list_con a {
    font-size: 13px;
    margin-bottom: 0.3rem;
}
.nayax_logo_con img {
    width: 85px;
}
.nayax_logo_con {
    padding-left: 15px;
}
.social_con li {
    padding: 0 15px;
}
.social_con li a img{
    width:15px;
    height:15px;
}
.social_con {
    display: inline-block;
    margin-right:20px;
}
.c_tag_wrap {
    padding: 0 0 10px 15px;
}
.c_tag_wrap h5 {
    font-size:0.65rem
}

    }

</style>
