<template>
	<div>
		<div v-if="btnVisible" class="smallButton" :style="{ backgroundColor: appData.colors.settings.primary }" @click="unsubscribe">
			{{ appData.emailUnsubscribeLinkText || "Unsubscribe" }}
		</div>
		<div v-if="!btnVisible">{{ msg }}</div>
	</div>
</template>

<script>
import globalMixin from '../mixins/global'

export default {
	name: 'Unsubscribe',
	mixins: [globalMixin],
	data() {
		return {
			btnVisible: false,
			msg: "User already unsubscribe or not exists",//TODO: set as default text in store
			appId: null,
			unsubCode: null,
		}
	},
	methods: {
		unsubscribe() {
			var self = this;
			this.$http.get(`/eReceiptSubscription/unsubscribe?unsubCode=${this.unsubCode}&ereceiptAppId=${this.appId}`)
				.then(response => {
					if (response.status == 200) {
						self.msg = self.appData.textData.site_success_unsubscribe_text || "Unsubscription Succeeded";//TODO: set as default text in store
					}
				}).then(() => {
					self.btnVisible = false;
				});
		}
	},
	created() {
		var self = this;
		self.unsubCode = self.$route.query.unsub_code;
		self.appId = self.appData.appData.appId;
		if (self.appData.textData.site_already_unsubscribe)
			self.msg = self.appData.textData.site_already_unsubscribe;

		this.$http.get(`/eReceiptSubscription/IsSubscribeExists?unsubCode=${self.unsubCode}&ereceiptAppId=${self.appId}`)
			.then(response => {
				self.btnVisible = response.data;
			});
	}
}
</script>

<style scoped>
</style>
