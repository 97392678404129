<template>
    <div>
        <div v-if="transactionsData.length > 0" id="resSelect" style="font-size: 1.05rem; padding-bottom: 9px; width: 50%">
            {{ this.subTitleText }}
        </div>
        <div id="gridPanel" class="siteBodyMiddle" style="overflow: auto; max-height: 420px; line-height: 14px; min-height: 400px">
            <div v-if="error" style="font-size: 1.05rem; padding-bottom: 9px; width: 50%; line-height: 24px">
                <span>{{ error }}</span>
            </div>
            <div v-else class="grid-wrapper">
                <div class="grid-row grid-header">
                    <div>{{ appData.textData.site_header_transaction_id }}</div>
                    <div v-if="confirmationPage">{{ appData.textData.site_header_last4digits }}</div>
                    <div>{{ appData.textData.site_header_date_time }}</div>
                    <div>{{ appData.textData.site_header_product }}</div>
                    <div>{{ appData.textData.site_header_amount }}</div>
                    <div>{{ appData.textData.site_header_location }}</div>
                    <div v-if="!confirmationPage">{{ appData.textData.site_header_select }}</div>
                </div>
                <div class="grid-row" v-for="tran in transactionsData" :key="tran.id" @click="rowClick(tran)">
                    <div>{{ tran.transaction_id }}</div>
                    <div v-if="confirmationPage">{{ tran.card_end }}</div>
                    <div>{{ tran.seTime | prettyDateTime }}</div>
                    <div>{{ tran.product_name }}</div>
                    <div>{{ currencySign + " " + tran.seValue }}</div>
                    <div>{{ tran.actor_description }}</div>
                    <div v-if="!confirmationPage"><input type="checkbox" v-model="tran.selected" /></div>
                </div>
                <div class="grid-row grid-footer">
                    <div></div>
                    <div v-if="confirmationPage"></div>
                    <div></div>
                    <div>{{ appData.textData.site_header_total }}</div>
                    <div>{{ currencySign + " " + this.getTotalAmount }}</div>
                    <div></div>
                    <div v-if="!confirmationPage"></div>
                </div>
            </div>
            <br />
        </div>

        <div class="sitePrintBodyButtons">
            <div class="bigButton" :style="{ backgroundColor: appData.colors.purchaseHistory.secondary }" @click="back">
                {{ backBtnText }}
            </div>

            <div id="submitBtn" class="smallButton" :style="{ backgroundColor: appData.colors.purchaseHistory.primary }" @click="submit">
                {{ submitBtnText }}
            </div>
        </div>
    </div>
</template>

<script>

    import globalMixin from '../mixins/global'

    export default {
        name: 'ChooseReceipt',
        mixins: [globalMixin],
        data() {
            return {
                confirmationPage: null,
                headlineText: null,// "Passenger Payment History",
                subTitleText: null,
                backBtnText: null,
                submitBtnText: null,
                transactionsData: [],
                allTransactionsData: [],
                currencySign: '',
                error: null,
            }
        },
        props: {
            fromDate: { type: String, default: null },
            toDate: { type: String, default: null },
            cardNumber: { type: String },
            subscriptionId: { type: Number },
            sendEmail: { type: Boolean }
        },
        computed: {
            getTotalAmount: function () {
                let sum = 0;
                this.transactionsData.forEach(function (item) {
                    sum += item.seValue;
                });
                return Math.round(sum * 100) / 100;
            }
        },
        mounted: function () {
            this.$forceUpdate();
            var self = this;
            this.confirmationPage = false;
            this.$http.post("ereceiptsubscription/sales-data", {
              fromDate: self.fromDate,
              toDate: self.toDate,
              cardNumber: self.cardNumber,
              appActorId: self.appData.appData.actorId,
            })
                .then(response => {
                    if (response.status == 200) {
                        if (response.data.length > 0) {
                            self.transactionsData = response.data;
                            self.currencySign = self.transactionsData && self.transactionsData.length > 0 ? self.transactionsData[0].currency_sign : "";
                        }
                        else {
                            self.error = this.data.errors.site_err_no_trans;
                        }
                    }
                });
        },
        methods: {
            back() {
                if (!this.confirmationPage) {
                    this.$router.back();
                }
                else {
                    this.confirmationPage = false;
                    this.transactionsData = this.allTransactionsData;
                }
            },
            submit() {
                var self = this;
                if (!self.confirmationPage) {
                    self.confirmationPage = true;
                    self.allTransactionsData = self.transactionsData;
                    self.transactionsData = self.transactionsData.filter(item => item.selected);
                    if (self.sendEmail) {
                        if (self.transactionsData == null || self.transactionsData.length == 0) {
                            self.$awn.alert('You didnt select any ereceipt')
                            return;
                        }

                        self.$http.post(`ereceiptsubscription/send-receipts?subscriptionId=${self.subscriptionId}`, self.transactionsData)
                            .then(() => {
                                //TODO alert
                            });
                    }
                }
                else {
                    window.print();
                }
            },
            rowClick(tran) {
                this.$set(tran, 'selected', !tran.selected);
            }
        },
        watch: {
            confirmationPage: function (newVal) {
                var self = this;
                if (newVal) {
                    this.headlineText = this.appData.textData.site_receipt_apply;
                    this.subTitleText = this.appData.textData.site_receipt_thank;
                    this.submitBtnText = this.appData.textData.btn_confirmation_submit;
                    this.backBtnText = this.appData.textData.btn_confirmation_back;
                }
                else {
                    this.headlineText = this.appData.textData.site_res_headline;
                    this.subTitleText = self.appData.textData.site_res_select;
                    this.submitBtnText = this.appData.textData.btn_purchaseHistory_submit;
                    this.backBtnText = this.appData.textData.btn_purchaseHistory_back;
                }

                this.$store.commit('setPageHeader', this.headlineText);
            }
        }
    }

</script>

<style scoped>
    .grid-wrapper {
        display: table;
        border: 4px solid #336699;
        border-radius: 6px;
        transition: all ease 0.5s;
        width: 98%;
    }

    .grid-header {
        font-weight: bold;
        color: white;
        border-bottom: 4px solid #f6f6f6;
    }

        .grid-header div {
            background: #336699;
            white-space: nowrap;
        }

    .grid-footer {
        font-weight: bold;
        color: white;
        border-bottom: 4px solid #f6f6f6;
    }

        .grid-footer div {
            background: #336699;
        }

    .grid-row {
        display: table-row;
    }

        .grid-row > div {
            display: table-cell;
            padding: 8px 8px;
            text-align: center;
            cursor: pointer;
        }

    .grid-wrapper > div:nth-child(even) {
        background: #f6f6f6;
        transition: all ease 0.4s;
    }

    .grid-wrapper > div:nth-child(odd) {
        background: #fafafa;
        transition: all ease 0.4s;
    }

    .grid-wrapper > div:hover {
        background: #a9d6ff;
        transition: all ease 0.4s;
    }

    .rtl-language .sitePrintBodyButtons {
        margin-left: 20px;
        margin-right: auto;
        float: left;
    }

    .sitePrintBodyButtons {
        margin-right: 20px;
        float: right;
        margin-top: 15px;
        width: 50%;
    }
</style>
