<template>
 <div class="app" :style="{opacity: isEmailSending ? '0.8' : '1' }">
 <SendingOverlay v-show="isEmailSending" />
  <div class="row ereceiptImg">
      <img src="../assets/images/exit_icon.svg"/>
    </div>
     <div class="row transTitle">
      <span class="title">E-Receipt</span>
      <div class="separator_line"></div>
      <div class="transInfo">
      <span class="transName">Receipt #</span>
      <span class="transNum">{{ tranId }}</span>
    </div>
    </div>    

    <div class="ereceipt_handlers_wrap col">
                    <Row>
                        <div class="button_group" >
                          <a 
                                target="_blank"
                                class="sendButton"
                               style=" text-align: center"
                                :style="{
                                 direction: isRtl ? 'rtl':'ltr',
                                 display: isEmailFlow ? 'none' : 'block',
                                 display: isInvalidTransaction ? 'none' : 'block'
                                }"
                                :href="receiptDownloadUrl"
                                :download="downloadName"
                            >
                                <div class="save_img" >
                                    <img src="../assets/images/save.svg"/>
                                    <span>{{ ('Save') }}</span>
                                </div>
                            </a>
                            <div class="btnSpace" :style="{display: isEmailFlow? 'none' : 'block'}"></div>
                            <div
                                class="send_button_container"
                                :class="isEmailFlow ? 'send_button_long' : 'send_button_short'"
                                :style="{                        
                                 direction: isRtl ? 'rtl':'ltr',
                                 margin: isEmailFlow ? '20px 0px 0px 0px' : '20px 0px 0px 35px',
                                 display: isInvalidTransaction ? 'none' : 'block'
                                }"
                            >
                                <button
                                    id="sendReceiptBtn"
                                    class="sendButton"
                                    :class="isEmailFlow ? 'sendReceiptEmail' : 'sendReceipt'"
                                    @click="sendEreceiptHandle"
                                    :disabled="isEmailSending"
                                >
                                    <img
                                        src="../assets/images/send.svg"
                                        style="margin-right: 6px"
                                        :style="{ display: isEmailFlow ? 'none' : 'inline-block' }"
                                    />
                                    <span>{{ ('Send') }}</span>
                                </button>

                                <input
                                    v-model="email"
                                    class="ereceipt_email_input"
                                    :class="{
                                        email_flow_input: isEmailFlow,
                                    }"
                                    ref="ereceipt_email_input"
                                    
                                    :style="{
                                        borderColor: isValidErr ? '#AA0000' : '#262626',
                                    }"
                                />
                                <div class="err_validation_con" v-show="isValidErr && isEmailFlow">
                                    <span class="err">{{ errors }}</span>
                                </div>
                          
                            </div>
                        </div>
                        <div class="err_validation_transaction" v-show="isInvalidTransaction">
                                    <span class="err">{{ errors }}</span>
                    </div>
                    </Row>
                    </div>
                 
    <AppFooter/>
 </div>                 
</template>

<script>
import Row from '@/components/RowSection.vue';
import SendingOverlay from '@/components/SendingOverlay.vue';
import AppFooter from '@/components/AppFooter.vue';

export default {
  components: { Row, SendingOverlay, AppFooter },
  name: "Qr",
  data: () => ({
    err: "",
    status: "",
    tranId: null,
    serial: null,
    site: null,
    receiptDownloadUrl: null,
    isEmailFlow: false,
    isEmailSending: false,
    email: '',
    isValidErr: false,
    isInvalidTransaction: false,
    isAppErr: false,
    isEmailSuccess: false,
    errors: '',
    isRtl: false
}),
beforeMount() {
   this.getEreceipt(true);
},
  methods: {
    
    getEreceipt(isFirst) {
      var self = this;
      self.status = "alert alert-warning";
      var result = this.$http
        .post(
          `eReceiptSite/ereceipt/${self.tranId}/${self.serial}/${self.site}`
        ).then(           
          response => {
            if(isFirst != true)
             window.location.href = response.data.receiptUrl         
        }).catch( error =>  {
            if ( (error.response?.data?.details?.toLowerCase().includes("failed get ereceipt")) && (error.response?.status == 400) )
            {
            self.errors = "Invalid Transaction";
            self.isInvalidTransaction = true;
            }
        });
    },
    cancelEmailFlow() {
                this.isEmailFlow = false;
            },
            validateEmail(emailAddress) {
                emailAddress = typeof emailAddress === 'object' ? emailAddress.val() : emailAddress;
                var localPartRgx = /^(([^<>()[\]\\.,;:\s@"']+(\.[^<>()[\]\\.,;:\s@"']+)*)|(".+"))@/;
                var domainRgx =
                    /((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                var rgex = new RegExp(localPartRgx.source + domainRgx.source);
                return rgex.test(emailAddress);
            },
            handleValidateEmail() {
                var self = this;
                var result = self.validateEmail(self.email);
                if (!result) {
                    self.errors = "Invalid email address";
                    self.isValidErr = true;
                } else {
                    self.errors = '';
                    self.isValidErr = false;
                }
                return result;
          },
    sendEreceiptHandle() {
        var self = this;
         
         if (!self.isEmailFlow) {
            self.isEmailFlow = true;
             setTimeout(() => {
                  self.$refs.ereceipt_email_input.focus();
                  }, 1000);
         } else if (self.isEmailFlow) {
             if (!self.handleValidateEmail()) return;

                self.isEmailSending = true;

             this.$http.post(
             `eReceiptSite/ereceipt/${self.tranId}/${self.serial}/${self.site}`+
              (self.email ? `?email=${self.email}` : '')
         )
                  .then(() => {
                       self.isEmailSuccess = true;

                      setTimeout(() => {
                         self.isEmailSuccess = false;
                         self.isEmailSending = false;
                         self.cancelEmailFlow();
                         }, 3000);
                        })
                         .catch(() => {
                             self.errors = "Email sending has failed";
                             self.isValidErr = true;
                             self.isEmailSuccess = false;
                             self.isEmailSending = false;
                         });
         }
     },
  },
  computed: {
    downloadName() {
      return "ereceipt" + this.tranId + ".pdf";
    }
  },
  created() {
    var self = this;
    self.tranId = self.$route.params.tranId;
    self.serial = self.$route.params.serial;
    self.site = self.$route.params.site;
    self.receiptDownloadUrl = `/api/eReceiptSite/ereceipt-download/${self.tranId}/${self.serial}/${self.site}`;
  }
};
</script>
<style scoped>
.app{
    flex-direction: column;
    box-sizing: border-box;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    min-height: 100%;
    display: flex;
    padding-top: 30px; 
    background-color: #F7F7F7;
}
.ereceipt_handlers_wrap .button_group img {
        width: 1.8rem;
    }

    .ereceipt_handlers_wrap .button_group button,
    .white_button {
        background-color: white;
        border: 1px solid #262626;
        color: #262626;
        text-transform: capitalize;
        font-size: 22px;
        width: 12rem;
        -webkit-transition: all 0.5s ease-in;
        transition: all 0.5s ease-in;
        opacity: 1;
        font-weight: 600;
}
    .ereceipt_handlers_wrap .button_group button:focus {
        outline: none;
    }

    .ereceipt_handlers_wrap.col {
      padding-bottom: 120px;
      direction: ltr;
      margin-top: 64px;
      display: flex;
      justify-content: space-around;
    }

    .ereceipt_email_input {
        background-color: #f7f7f7;
        font-size: 1.2rem;
        border: 1px solid #262626;
        color: #262626 !important;
        position: absolute;
        transition: all 0.3s ease-in;
        right: 0;
        bottom: 0;
        height: 70px;
        top: 0;
        border-radius: 440px;
        z-index: -1;
        visibility: hidden;
        outline: none;
    }
    .ereceiptImg {
     margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
    height: 180px;
    width: 258px;
    border-radius: 0px;
}
.ereceiptImg img{  
    height: 180px;
    width: 258px;
}

    .transTitle{
      color: #262626;
      font-family: 'Inter';
      flex-direction: column;
      display: flex;
      align-content: center;
      flex-direction: column;
      justify-items: center;
      align-items: center;
    }

    .title{
      position: absolute;
      height: 35px;
      font-style: normal;
      height: 35px;
      margin-left: 91px;
      margin-right: 91px;
      margin-bottom:52px;
      font-weight: 700;
      font-size: 36px;
      line-height: 34px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.16px;
    }

    .separator_line {
        white-space: normal;
        height: 45px;
        border-bottom: 1px solid #E4E4E4;
        width: calc(30% - 31px);
        display: inline-block;
        margin-bottom: 4px;
}

    .transInfo{
        margin-top:82px
    }

    .transName{
      font-size: 18px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.16px;
      text-align: left;
      height: 20px;
      width: 77px;
    }

    .transNum{
      font-size: 28px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.1599999964237213px;
      text-align: right;
      height: 19.464284896850586px;
      width: 149px;
      margin-left:10px;
    }

    .ereceipt_email_input.email_flow_input {
        width: 100%;
        visibility: visible;
        z-index: 1;
        padding: 18px;
    }
    .sendButton {
        height: 70px;
        background: #e0e0e0;
        border-radius: 50px;
        font-weight: 600;
        margin-top: 20px;
        cursor: pointer;
        background-color: white;
        border: 1px solid #262626;
        color: #262626;
        text-transform: capitalize;
        font-size: 22px;
        width: 15rem;
        -webkit-transition: all 0.5s ease-in;
        transition: all 0.5s ease-in;
        opacity: 1;
}
.btnSpace{
    width:150px;
}

    .send_button_container {
        position: relative;
        height: 70px;
        margin-top: 20px;
        margin-left: 35px;
    }
    .send_button_short{
        width:9rem;
    }
    .send_button_long{
        width:35rem;
    }
    .save_img img{
      margin-right: 6px;
      margin-top: 20px;
    }
    .save_img span{
      top: 15px;
      position: relative;
      font-weight: 600;
    }

    .err_validation_con {
    width: 100%;
    height: 30px;
    position: absolute;
    top: 5rem;
    background-color: #ffeded;
    z-index: 0;
    border-radius: 15px 15px 15px 15px;
}


  .err_validation_transaction{
    width: 100%;
    height: 30px;
    position: initial;
    top: 5rem;
    background-color: #ffeded;
    z-index: 0;
    border-radius: 15px 15px 15px 15px;
    text-align: center;
    align-self: center;
    margin: auto;
    padding-top: inherit;
    margin-top: 15px;
   }

    .ereceipt_handlers_wrap .button_group .send_button_container button {
        margin-top: 0;
        right: 0;
        z-index: 2;
        position: absolute;
    }

    .send_button_container span.err {
        position: absolute;
        bottom: 0.25rem;
        font-size: 1.1rem;
        left: 30%;
        color: #aa0000;
    }

    .button {
        width: 100px;
        height: 50px;
        -moz-box-shadow: inset 0 1px 0 0 #fff;
        -webkit-box-shadow: inset 0 1px 0 0 #fff;
        box-shadow: inset 0 1px 0 0 #fff;
        background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(0.05, #ffffff),
            color-stop(1, #d1d1d1)
        );
        background: -moz-linear-gradient(center top, #ffffff 5%, #d1d1d1 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#d1d1d1');
        background-color: #fff;
        -moz-border-radius: 6px;
        -webkit-border-radius: 6px;
        border-radius: 6px;
        border: 1px solid #dcdcdc;
        display: inline-block;
        color: #777;
        
        font-size: 15px;
        font-weight: 700;
        padding: 6px 24px;
        text-decoration: none;
        text-shadow: 1px 1px 0 #fff;
    }

    .button:hover {
        background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(0.05, #d1d1d1),
            color-stop(1, #ffffff)
        );
        background: -moz-linear-gradient(center top, #d1d1d1 5%, #ffffff 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d1d1d1', endColorstr='#ffffff');
        background-color: #d1d1d1;
    }
    .button:active {
        position: relative;
        top: 1px;
    }

    .button_group {
      margin-left: auto;
      margin-right: auto;
      display: inline-flex;
    }
    .semiBold {
        
        font-size: 13px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: -0.2599999010562897px;
        text-align: left;
    }
    table#ereceipt_table td:last-child {
        width: 70%;
        
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.1599999964237213px;
        text-align: right;
    }
    .ereceipt_handlers_wrap .button_group .hideAnimation {
        animation-name: hide-button;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }
    .ereceipt_handlers_wrap .button_group .sendReceiptEmail {
        width: 28%;
    }
    .ereceipt_handlers_wrap .button_group .sendReceipt {
        width: 15rem;
    }

    @media screen and (min-width: 320px) and (max-width: 700px) {
        .transInfo{
        margin-top:52px
    }
    .transName{
      font-size: 11px;
    }
    .transNum{
      font-size: 18px;
    }
    .title{
        font-size:20px;
    }
    .ereceiptImg{
      margin-top: 54px;
      height: 106px;
      width: 135px;
      border-radius: 0px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 40px;
    }
    .ereceiptImg img{
      height: 106px;
      width: 135px;
    }
    .sendButton {
        height: 50px;
        border-radius: 30px;
        font-weight: 500;        
        margin-top: 20px;
        font-size: 0.9rem;
        width: 9rem;
    }
    .ereceipt_handlers_wrap .button_group button,
    .white_button {
        font-size: 0.9rem;
        width: 9rem;
    }
    .ereceipt_handlers_wrap .button_group .sendReceipt {
        width: 9rem;
    }
    .btnSpace{
    width:1px;
}
  .save_img img{
      margin-top: 15px;
    }
    .save_img span{
      top: 10px;
    }
    .ereceipt_handlers_wrap .button_group img {
        width: 1.2rem;
    }
    .ereceipt_handlers_wrap.col {
      padding-bottom: 70px;
      margin-top: 54px;
    }
    .send_button_container {
        height: 50px;
    }
    .send_button_long{
        width:18rem;
    }
    .ereceipt_email_input {      
        font-size: 0.9rem;
        width: 5rem;
        height: 50px;
    }
    .err_validation_con {
    top: 3.5rem;
}

.send_button_container span.err{
    font-size: 0.8rem;
    left:3%
}

.err_validation_transaction{
    width: 100%;
   }
}

</style>
